// ** Next Imports
import 'src/utils/initialConfig'
import Head from 'next/head'
import { Router } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

import { ToastContainer } from 'react-toastify'

import { reduxWrapper } from '../store/wrapper'

import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'

// ** Config Imports
import themeConfig from 'src/configs/themeConfig'

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout'
import ThemeComponent from 'src/@core/theme/ThemeComponent'

// ** Contexts
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

import 'react-toastify/dist/ReactToastify.css'

// ** Global css styles
import '../../styles/globals.css'

import { GlobalModal } from '../views/modal/GlobalModal'
import { RoleProvider } from 'src/@core/context/roleContext'

// ** Date picker Import
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { RecoilRoot } from 'recoil'
import Script from 'next/script'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { userGetProfile } from 'src/store/user/auth/actions'
import { UserProfile } from 'src/types/common'
import { useSelector } from 'react-redux'
import { AppState } from 'src/store/types'

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const userProfile = useSelector<AppState, UserProfile | undefined>(state => state.userLogin.profile)

  // Variables
  const getLayout = Component.getLayout ?? (page => <UserLayout>{page}</UserLayout>)
  const locationHost = typeof window !== 'undefined' ? window.location.host : ''
  const currentUrl = typeof window !== 'undefined' ? window.location.href : ''

  const dispatch = useDispatch()

  useEffect(() => {
    const handleRouteChange = () => {
      if (currentUrl.includes(`${locationHost}/admin`) || !userProfile) {
        return
      } else {
        dispatch(userGetProfile())
      }
    }

    Router.events.on('routeChangeComplete', handleRouteChange)

    // Cleanup the event listener
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Router.events, dispatch, currentUrl, locationHost, userProfile])

  return (
    <>
      <Script
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
      />
      <Script
        id='gtag-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());

           gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}');
           `,
        }}
      />
      <CacheProvider value={emotionCache}>
        <RecoilRoot>
          <Head>
            <title>{`${themeConfig.templateName}`}</title>
            <meta name='description' content={`${themeConfig.templateName}`} />
            <meta name='keywords' content={themeConfig.templateName} />
            <meta name='viewport' content='initial-scale=1, width=device-width' />
            <meta name='robots' content='noindex' />
            <meta name='googlebot' content='noindex' />
            <meta name='google' content='notranslate' />
            {currentUrl.includes(`${locationHost}/user`) && (
              <>
                <title>チカミチ</title>
                <link rel='manifest' href='/chikamichi/manifest.json' />
                <link rel='shortcut icon' href='/chikamichi/icon.ico' />
                <link rel='apple-touch-icon' href='/chikamichi/apple-touch-icon.png' />
              </>
            )}
            {currentUrl.includes(`${locationHost}/management`) && (
              <>
                <title>ユーミル</title>
                <link rel='manifest' href='/u_miru/manifest.json' />
                <link rel='shortcut icon' href='/u_miru/icon.ico' />
                <link rel='apple-touch-icon' href='/u_miru/apple-touch-icon.png' />
              </>
            )}
            {currentUrl.includes(`${locationHost}/admin`) && (
              <>
                <title>ユーミル／チカミチ管理</title>
              </>
            )}
          </Head>

          <SettingsProvider>
            <SettingsConsumer>
              {({ settings }) => {
                return (
                  <RoleProvider>
                    <GlobalModal>
                      <ThemeComponent settings={settings}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          {getLayout(<Component {...pageProps} />)}
                        </LocalizationProvider>
                      </ThemeComponent>
                    </GlobalModal>
                  </RoleProvider>
                )
              }}
            </SettingsConsumer>
          </SettingsProvider>
          <ToastContainer
            position='top-right'
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            draggable={false}
            closeOnClick
            pauseOnHover={false}
          />
        </RecoilRoot>
      </CacheProvider>
    </>
  )
}

export default reduxWrapper.withRedux(App)
