import { all } from 'redux-saga/effects'

import adminLoginSaga from 'src/pages/admin/login/saga'
import adminTrainingContentSaga from 'src/store/admin/training_content/saga'

import userLoginSaga from 'src/store/user/auth/saga'
import userTrainingContentCategoriesSaga from 'src/store/user/training_content_categories/saga'
import userTrainingContentsSaga from 'src/store/user/training_contents/saga'
import userTrainingContentSaga from 'src/store/user/training_content/saga'
import userUpdatePasswordSaga from 'src/store/user/update_password/saga'

import managementTmsUsersSaga from 'src/store/management/tms/users/saga'

function* rootSaga() {
  yield all([
    adminLoginSaga(),
    adminTrainingContentSaga(),
    userLoginSaga(),
    userTrainingContentCategoriesSaga(),
    userTrainingContentsSaga(),
    userTrainingContentSaga(),
    userUpdatePasswordSaga(),
    managementTmsUsersSaga(),
  ])
}

export default rootSaga
