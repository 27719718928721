import axios from 'src/utils/axios'
import { LoginForm, AuthInfo, UserProfile } from 'src/types/common'
import { RawAxiosResponseHeaders, AxiosResponseHeaders } from 'axios'

export type ForgotPasswordType = {
  email: string
  redirectUrl: string
}

export type EditPasswordType = {
  password: string
  passwordConfirmation: string
}

type GetResetPasswordTokenResponseType = {
  redirectHeaders: AuthInfo & { accessToken: string }
}

export type GetResetPasswordTokenType = {
  resetPasswordToken: string
}


export const login = async (data: LoginForm): Promise<{
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
  data: { data: UserProfile }
}> => {
  const res = await axios.post('/account/login', data)

  return res
}

export const logout = async () => {
  const res = await axios.delete('/account/logout')

  return res
}

export const profile = async () => {
  const res = await axios.get('/account/profile')

  return res.data
}

export const forgotPassword = async (data: ForgotPasswordType) => {
  const res = await axios.post('/account/password/reset', data)

  return res.data
}

export const editPassword = async (data: EditPasswordType, headers: AuthInfo) => {
  const res = await axios.put('/account/password/reset', data, { headers })

  return res
}


export const getResetPasswordToken = async (
  params: GetResetPasswordTokenType
): Promise<GetResetPasswordTokenResponseType> => {
  const res = await axios.get('/account/password/reset', { params })

  return res.data
}
