import { createAction } from '@reduxjs/toolkit'

import { AuthInfo, LoginForm, LogoutPayload, UserProfile } from 'src/types/common'

export const userLogin = createAction<LoginForm>('USER_AUTH_LOGIN')
export const userLoginSuccess = createAction<AuthInfo>('USER_AUTH_LOGIN_SUCCESS')
export const userLoginFailure = createAction<void>('USER_AUTH_LOGIN_FAILURE')

export const userGetProfile = createAction('USER_GET_PROFILE')
export const userGetProfileSuccess = createAction<UserProfile>('USER_GET_PROFILE_SUCCESS')

export const userLogout = createAction<LogoutPayload>('USER_AUTH_LOGOUT')
